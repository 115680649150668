:root {
	--amplify-primary-color: #b51717;
	--amplify-primary-tint: #8e1212;
	--amplify-primary-shade: #b51717;
}

body {
	background-color: #fff;
	font-family: 'Raleway', sans-serif;
	min-height: 100vh;
	margin: 0;
}

a {
	text-decoration: none;
	color: #000;
	cursor: pointer;
}
